<template>
  <b-modal
    id="full-report-modal"
    class="position-relative"
    title="Full Report"
    size="xl"
    ok-title="Close"
    ok-only
  >
    <b-overlay :show="isLoading">
      <div class="d-flex justify-content-end">
        <b-button
          variant="primary"
          class="mr-4"
          @click="exportPdf"
        >
          <feather-icon icon="FileTextIcon" />
          Export PDF
        </b-button>
      </div>
      <b-card id="employees-report">
        <b-card>
          <h4>Summary</h4>

          <table
            border="1"
            width="100%"
            cellPadding="10"
          >
            <tr class="text-center">
              <th>Current Monthly Expenses</th>
              <th>Adjusted Monthly Expenses</th>
              <th>Saved</th>
            </tr>

            <tr class="text-right">
              <td>
                <div class="d-flex justify-content-between">
                  <h6>Total Salary</h6>
                  <h6>{{ formatAmount(currentTotal) }}</h6>
                </div>
                <div class="d-flex justify-content-between">
                  <h6>Total Coverage Costs</h6>
                  <h6>{{ formatAmount(totalCoverageCosts.isCurrent) }}</h6>
                </div>
              </td>
              <td>
                <div class="d-flex justify-content-between">
                  <h6>Total Salary</h6>
                  <h6>{{ formatAmount(recommendedTotal) }}</h6>
                </div>
                <div class="d-flex justify-content-between">
                  <h6>Total Coverage Costs</h6>
                  <h6>{{ formatAmount(totalCoverageCosts.isRecommended) }}</h6>
                </div>
              </td>
              <td>
                <h5>
                  {{ formatAmount(recommendedTotal ? (currentTotal - recommendedTotal) : 0) }}
                  ({{ (!Number.isNaN(recommendedTotal / currentTotal) && recommendedTotal > 0) ? 100 - ((recommendedTotal / currentTotal) * 100).toFixed() : 0 }}%)
                </h5>
              </td>
            </tr>
          </table>
        </b-card>

        <div
          v-for="category in categories"
          :key="category"
          class="mb-4"
        >
          <h4
            v-if="Object.values(category === 'current' ? currentEmployees : recommended).length"
          >{{ category === 'current' ? 'Company' : 'Recommended' }} Employees</h4>
          <div
            v-for="employeeType in Object.keys(category === 'current' ? currentEmployees : recommended)"
            :key="employeeType"
          >
            <div v-if="category === 'current' ? currentEmployees[employeeType] : recommended[employeeType]">
              <h6>{{ getEmployeeType(employeeType) }}</h6>
              <b-table
                :fields="employeesFields"
                :items="category === 'current' ? currentEmployees[employeeType] : recommended[employeeType]"
                striped
                responsive
                show-empty
              >
                <template #head()="data">
                  <span class="text-capitalize">
                    {{ data.label }}
                  </span>
                </template>
                <template #cell(id)="data">
                  {{ data.index + 1 }}
                </template>
                <template #cell(region)="data">
                  {{ data.item.region.country }} - {{ data.item.region.city }}
                </template>
                <template #cell(salary)="data">
                  {{ formatAmount(data.item.salary) }}
                </template>
                <template #cell(gender)="data">
                  <span class="text-capitalize">{{ data.item.gender.toLowerCase() }}</span>
                </template>
                <template #cell(coverageQuality)="data">
                  <span class="text-capitalize">{{ data.item.coverageQuality.toLowerCase() }}</span>
                </template>

                <template #row-details="data">
                  <div class="mx-6">
                    <div
                      v-if="hasCoverages(data.item.region.id) && getCoverages(data.item.region.id, data.item).length"
                      class="mb-2"
                    >
                      <h5>Coverage Plans Covered by Employer</h5>
                      <b-row>
                        <b-col
                          v-for="coverage in getCoverages(data.item.region.id, data.item)"
                          :key="coverage.id"
                          md="4"
                        >
                          <div class="d-flex">
                            <p class="font-weight-bold">
                              {{ coverage.category.name }} ({{ getContribution(data.item.contributions, coverage.category.id) }}%):
                            </p>
                            <p class="ml-1">
                              {{ formatAmount(coverage.amount ? coverage.amount : 0) }}
                            </p>
                          </div>
                        </b-col>
                      </b-row>
                    </div>
                    <!-- Dependents -->
                    <div v-if="data.item.dependents.length">
                      <hr>
                      <h5 class="mb-2">
                        Dependents of {{ data.item.name }}
                      </h5>
                      <b-table
                        :items="data.item.dependents"
                        :fields="dependentFields"
                        striped
                        responsive
                        show-empty
                      >
                        <template #head()="row">
                          <span class="text-capitalize">{{ row.label }}</span>
                        </template>
                        <template #cell(id)="row">
                          {{ row.index + 1 }}
                        </template>
                        <template #cell(gender)="row">
                          <span class="text-capitalize">{{ row.item.gender.toLowerCase() }}</span>
                        </template>
                        <template #cell(relationship)="row">
                          <span class="text-capitalize">{{ row.item.relationship.toLowerCase() }}</span>
                        </template>
                        <template #cell(coverageQuality)="row">
                          <span class="text-capitalize">{{ row.item.coverageQuality.toLowerCase() }}</span>
                        </template>
                        <template #row-details="row">
                          <div class="mx-6">
                            <h5>Coverage Plans Covered by Employer</h5>

                            <b-row>
                              <b-col
                                v-for="coverage in getCoverages(data.item.region.id, row.item)"
                                :key="coverage.id"
                                md="4"
                              >
                                <div class="d-flex">
                                  <p class="font-weight-bold">
                                    {{ coverage.category.name }} ({{ getContribution(row.item.contributions, coverage.category.id) }}%):
                                  </p>
                                  <p class="ml-1">
                                    {{ formatAmount(coverage.amount ? coverage.amount : 0) }}
                                  </p>
                                </div>
                              </b-col>
                            </b-row>
                          </div>
                        </template>
                      </b-table>
                    </div>
                  </div>
                </template>
              </b-table>
            </div>
          </div>
        </div>
      </b-card>
    </b-overlay>
  </b-modal>
</template>

<script>
import {
  BModal, BTable, BButton, BCard, BOverlay, BRow, BCol,
} from 'bootstrap-vue'
import html2pdf from 'html2pdf.js'

export default {
  components: {
    BModal,
    BTable,
    BButton,
    BCard,
    BOverlay,
    BRow,
    BCol,
  },
  props: {
    employees: {
      type: Object,
      required: true,
    },
    recommendedEmployees: {
      type: Object,
      required: true,
    },
    currentTotal: {
      type: Number,
      required: true,
    },
    recommendedTotal: {
      type: Number,
      required: true,
    },
    regions: {
      type: Array,
      required: true,
    },
    totalCoverageCosts: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      employeesFields: [
        { key: 'id', label: 'S.No.', tdClass: 'w-5' },
        { key: 'name', tdClass: 'w-25' },
        'age', 'gender', 'region', 'salary', 'coverageQuality',
      ],
      dependentFields: [
        { key: 'id', label: 'S.No.', tdClass: 'w-5' },
        'name', 'age', 'gender', 'relationship', 'coverageQuality',
      ],
      categories: ['current', 'recommended'],
      coveragesByCategory: [],
      isLoading: false,
    }
  },
  computed: {
    currentEmployees() {
      const employees = {}
      if (Object.values(this.employees).length) {
        Object.entries(this.employees).forEach(([key, category]) => {
          employees[key] = category.map(employee => ({
            ...employee,
            dependents: employee.dependents.map(dependent => ({
              ...dependent,
              _showDetails: true,
            })),
            _showDetails: true,
          }))
        })
      }
      return employees
    },
    recommended() {
      const employees = {}
      if (Object.values(this.recommendedEmployees).length) {
        Object.entries(this.recommendedEmployees).forEach(([key, category]) => {
          employees[key] = category.map(employee => ({
            ...employee,
            dependents: employee.dependents.map(dependent => ({
              ...dependent,
              _showDetails: true,
            })),
            _showDetails: true,
          }))
        })
      }
      return employees
    },
  },
  methods: {
    getEmployeeType(type) {
      switch (type) {
        case 'HOURLY':
          return 'Hourly Employees'
        case 'SALARY':
          return 'Salaried Employees'
        default:
          return 'Contracted Providers'
      }
    },
    getCoverages(region, employee) {
      if (this.regions.length) {
        const contributions = employee.contributions.reduce((r, a) => {
          // eslint-disable-next-line no-param-reassign
          r[a.coverageCategory.name] = r[a.coverageCategory.name] || []
          r[a.coverageCategory.name].push(a)
          return r
        }, Object.create(null))

        const allCoverages = this.regions.find(reg => reg.id === region).coverages.data
        const coverages = allCoverages.filter(coverage => coverage.minAge <= employee.age && coverage.maxAge >= employee.age).map(coverage => ({
          ...coverage,
          amount: (coverage[employee.coverageQuality.toLowerCase()] * (contributions[coverage.category.name]?.[0]?.contribution / 100)) / 12 ?? 0,
        }))

        return coverages
      }
      return []
    },
    getContribution(contributions, category) {
      return contributions.find(contribution => contribution.coverageCategory.id === category)?.contribution ?? 0
    },
    hasCoverages(region) {
      if (this.regions.length) return this.regions.find(reg => reg.id === region).coverages.total
      return 0
    },
    exportPdf() {
      const element = document.getElementById('employees-report')

      const opt = {
        margin: 0,
        filename: 'wage-report.pdf',
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: {
          scale: 2,
          width: element.offsetWidth * 0.65,
          pagebreak: { mode: ['avoid-all', 'css', 'legacy'] },
        },
        jsPDF: { format: 'a4', orientation: 'portrait' },
      }

      // New Promise-based usage:
      html2pdf().set(opt).from(element).save()
    },
  },
}
</script>

<style lang="scss">
#full-report-modal {
  .export-pdf-btn {
    right: 20px;
    top: 10px;
    z-index: 1;
  }
  .w-5 {
    width: 5%;
  }
  .mx-6 {
    margin-left: 6rem;
    margin-right: 6rem;
  }
}
</style>
