<template>
  <div>
    <b-row>
      <b-col md="6">
        <b-form-group label="Company Employees">
          <v-select
            v-model="employeeIds"
            class="v-selection"
            :options="employeesOptions"
            :reduce="item => item.value"
            placeholder="Select Company Employees"
            multiple
          />
        </b-form-group>
      </b-col>

      <b-col md="6">
        <b-form-group label="System Users">
          <v-select
            v-model="userIds"
            class="v-selection"
            :options="systemUsersOptions"
            :reduce="item => item.value"
            placeholder="Select System Users"
            multiple
          />
        </b-form-group>
      </b-col>
    </b-row>

    <input-employees
      :employees="allEmployees"
      confirm-modal="recommended-employees-modal"
      :company="company"
      :active-input-type="activeInputType"
      :active-input-child="activeInputChild"
      :regions="regions"
      @remove-employee="(id, type) => removeCurrentEmployee(id, type)"
      @employees-updated="() => { $emit('employees-updated') }"
    />
  </div>
</template>

<script>
import vSelect from 'vue-select'
import { BFormGroup, BRow, BCol } from 'bootstrap-vue'
import useApollo from '@/plugins/graphql/useApollo'
import InputEmployees from './InputEmployees.vue'

export default {
  components: {
    vSelect,
    InputEmployees,
    BFormGroup,
    BRow,
    BCol,
  },
  props: {
    currentEmployees: {
      type: Object,
      default: () => {},
    },
    activeInputType: {
      type: [Array, Object],
      default: () => {},
    },
    activeInputChild: {
      type: [Array, Object],
      default: () => {},
    },
    employees: {
      type: Object,
      default: () => {},
    },
    company: {
      type: Object,
      default: () => {},
    },
    regions: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      employeeIds: [],
      userIds: [],
      allEmployees: {},
      users: [],
      selectedCompany: this.$store.state.project.selectedCompany,
    }
  },
  computed: {
    employeesOptions() {
      if (this.currentEmployees) {
        const allCurrentEmployees = this.getAllCurrent

        return allCurrentEmployees.map(employee => ({
          value: employee.id,
          label: employee.name,
        }))
      }
      return []
    },
    systemUsersOptions() {
      return this.users.map(user => ({
        value: user.uuid,
        label: user.name,
      }))
    },
    getAllRecommended() {
      return [
        ...(this.employees?.onSite ? JSON.parse(JSON.stringify(this.employees.onSite)) : []),
        ...(this.employees?.remote ? JSON.parse(JSON.stringify(this.employees.remote)) : []),
        ...(this.employees?.remoteOffshore ? JSON.parse(JSON.stringify(this.employees.remoteOffshore)) : []),
      ]
    },
    getAllCurrent() {
      return [
        ...(this.currentEmployees?.onSite ? this.currentEmployees.onSite : []),
        ...(this.currentEmployees?.remote ? this.currentEmployees.remote : []),
      ]
    },
  },
  watch: {
    employeeIds(val) {
      const currentOnSite = this.currentEmployees?.onSite?.filter(employee => val.includes(employee.id)) ?? []
      const currentRemote = this.currentEmployees?.remote?.filter(employee => val.includes(employee.id)) ?? []

      currentRemote.forEach(employee => {
        if (!this.allEmployees.remote?.find(emp => emp.id === employee.id)) {
          this.allEmployees.remote.push(employee)
        }
      })
      currentOnSite.forEach(employee => {
        if (!this.allEmployees.onSite?.find(emp => emp.id === employee.id)) {
          this.allEmployees.onSite.push(employee)
        }
      })

      this.allEmployees.remote.forEach(employee => {
        if (!val.includes(employee.id)) {
          this.allEmployees.remote.splice(this.allEmployees.remote.indexOf(employee), 1)
        }
      })

      this.allEmployees.onSite.forEach(employee => {
        if (!val.includes(employee.id)) {
          this.allEmployees.onSite.splice(this.allEmployees.onSite.indexOf(employee), 1)
        }
      })
    },
    userIds(val) {
      val.forEach(uuid => {
        if (!this.allEmployees.systemUsers.find(emp => emp.uuid === uuid)) {
          const user = this.users.find(u => u.uuid === uuid)
          this.allEmployees.systemUsers.push({
            ...user,
            gender: 'MALE',
            coverageQuality: 'LOW',
            region: { id: null },
            workModality: null,
            dependents: [],
            contributions: [],
          })
        }
      })
      this.allEmployees.systemUsers.forEach(employee => {
        if (!val.includes(employee.uuid)) {
          this.allEmployees.systemUsers.splice(this.allEmployees.systemUsers.indexOf(employee), 1)
        }
      })
    },
  },
  mounted() {
    if (this.employees) {
      this.allEmployees = {
        ...this.employees,
        systemUsers: [],
      }
    } else {
      this.allEmployees = {
        remote: [],
        onSite: [],
        systemUsers: [],
      }
    }
    this.searchUsers()
    this.getAllRecommended.forEach(employee => {
      if (this.employeesOptions.find(emp => emp.value === employee.id)) {
        this.employeeIds.push(employee.id)
      }
    })
  },
  methods: {
    removeCurrentEmployee(id, type = null) {
      switch (type) {
        case 'os':
          if (this.allEmployees[type]) {
            this.allEmployees.onSite.splice(this.allEmployees[type].find(employee => employee.id === id), 1)
          }
          this.employeeIds.splice(this.employeeIds.indexOf(id), 1)
          break
        case 'rm':
          if (this.allEmployees[type]) {
            this.allEmployees.remote.splice(this.allEmployees[type].find(employee => employee.id === id), 1)
          }
          this.employeeIds.splice(this.employeeIds.indexOf(id), 1)
          break
        case 'rmo':
          if (this.allEmployees[type]) {
            this.allEmployees.remoteOffshore.splice(this.allEmployees[type].find(employee => employee.id === id), 1)
          }
          break
        case 'system':
          if (this.allEmployees.systemUsers) {
            this.allEmployees.systemUsers.splice(this.allEmployees.systemUsers.find(employee => employee.id === id), 1)
          }
          this.userIds.splice(this.userIds.indexOf(id), 1)
          break
        default:
      }
    },
    searchUsers() {
      if (!this.users.length) {
        useApollo.calculator.getSystemUsers({
          company: this.selectedCompany,
        }).then(response => {
          const users = response.data.company.users.data
          this.users = users.filter(user => !user.isAdmin && user.companyRoles.data[0].role.displayName !== 'Company Owner').filter(user => !this.getAllRecommended.find(employee => employee.uuid === user.uuid))
        })
        this.$emit('no-lazy')
      }
    },
  },
}
</script>
